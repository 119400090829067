export enum SepSessionStorageKeys {
  SEP_IMPERSONATION_SESSION_DATA = 'sepImpersonationSessionData',
}

export interface SepImpersonationSessionData {
  organization: {
    id: string;
    name: string;
    subscriptions: Array<{
      id: string;
      numberOfLicenses: number;
      payment: { paid: boolean; deadline: string | null };
      status: 'active' | 'canceled' | 'past_due' | 'trialing';
      createdAt: string;
      expiresAt: string;
    }>;
    tenant: { id: string };
  };
}
