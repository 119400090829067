export enum UserRole {
  SEP_ADMIN = 'SEP_ADMIN',
  ORGANIZATION_ADMIN = 'ORG_ADMIN',
}

export interface CurrentUserUIModel {
  username: string;
  email: string;
  role: UserRole;
  organization: {
    id: string;
    name: string;
    subscriptions: Array<{
      id: string;
      numberOfLicenses: number;
      payment: { paid: boolean; deadline: string | null };
      status: 'active' | 'canceled' | 'past_due' | 'trialing' | 'unpaid';
      createdAt: string;
      expiresAt: string;
    }>;
    tenant?: { id: string };
  } | null;
  purchaseIntent: { id: string; priceId: string; quantity: number; freeTrial: boolean } | null;
}
